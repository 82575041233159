import request from "./request";

export function getList(data) {
	return request({
		url: "/adminmsg/list",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

export function getDetail(id) {
	return request({
		url: "/adminmsg/info",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data: {
			id,
		},
	});
}
