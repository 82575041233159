<template>
  <div class="drawer-container" v-if="value" @click.stop="$emit('input',false)" :style="{background:mask?'rgba(0, 0, 0, 0.5)':'rgba(0, 0, 0, 0)'}">
    <div class="drawer" :style="{width:value?`${width}vw`:''}" @click.stop>
      <div class="top">
        <p class="title">{{title}}</p>
        <i class="icon el-icon-close" @click.stop="$emit('input',false)"></i>
      </div>
      <section class="content">
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 30
    },
    title: {
      type: String,
      required: true
    },
    mask: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 2rem 0rem 0rem 2rem;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: drawer;
}
@keyframes drawer {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.top {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  width: 100%;
  padding: 0 1.5rem;
  .title {
    font-size: 1.04rem;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #000000;
    line-height: 1.04rem;
  }
  .icon {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.content {
  z-index: 1000;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}
</style>