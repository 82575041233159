<template>
  <div class="news-page view-page">
    <!-- 搜素 -->
    <!-- <c-search searchTitle="模糊查询" valueFormat="yyyyMMdd" @search="searchNews" placeholder="关键字" isBorder :isDate="true"></c-search> -->
    <!-- 分类 -->
    <!-- <c-navigation title="消息提醒" :list="approvalList" :isBorder="false"></c-navigation> -->
    <!-- 表格 -->
    <c-table :data="tableData">
      <!-- <el-table-column align="center" prop="approve_id" label="编号" width="100">
        <template #default="{ row }">
          <el-checkbox v-model="row.isChecked" @change="checkedClient(row)"></el-checkbox>
          {{ row.approve_id }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="title" label="消息名称"></el-table-column>
      <el-table-column align="center" prop="content" label="消息内容"></el-table-column>
      <el-table-column align="center" prop="status" label="状态"> </el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="260"></el-table-column>
      <el-table-column align="center" prop="date" label="操作">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list" :class="{ edit: row.isChecked }" @click="checkCase(row)">查看</p>
          </div>
        </template>
      </el-table-column>
    </c-table>
    <!-- 完成通知 -->
    <c-drawer v-model="showCaseFinishInform" :width="35" :title="info && info.title">
      <div class="caseContent">
        <div class="text-1">时间： {{ info.createtime }}</div>
        <div>消息内容：{{ info.content ? info.content : '无' }}</div>
      </div>
    </c-drawer>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="newsList.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import * as newsApi from '@/api/news'
export default {
  name: 'News',
  components: {
    CDrawer
  },
  data() {
    return {
      showCaseFinishInform: false, // 显示案件完成通知弹框
      selectedTable: [],
      // 消息提醒
      approvalList: [
        {
          id: 1,
          name: '未读',
          isChecked: true
        },
        {
          id: 2,
          name: '已读',
          isChecked: false
        }
      ],
      info: {},
      // 表格数据
      tableData: [],
      newsList: {
        page: 1,
        limit: 7
      },
      contactsTotal: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getList()
    },
    getList() {
      newsApi.getList(this.newsList).then(res => {
        console.log('res', res)
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    // 选中的项
    checkedClient(row) {
      let idx
      this.selectedTable.forEach((item, index) => {
        if (item.id === row.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectedTable.splice(idx, 1)
      } else {
        this.selectedTable.push(row)
      }
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.newsList.page = i
      this.getList()
    },
    // 查看消息
    checkCase(v) {
      newsApi.getDetail(v.id).then(res => {
        this.info = res.data
      })
      this.showCaseFinishInform = true
    },
    /**
     * 搜索
     */
    searchNews(e) {
      this.newsList.name = e.keyWord
      if (e.date) {
        this.newsList.starday = e.date[0]
        this.newsList.endday = e.date[1]
      } else {
        this.newsList.starday = ''
        this.newsList.endday = ''
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-page {
  padding: 2rem 0;

  background: #ffffff;
}

.edit {
  color: #f57d0a;
}

.caseContent {
  padding: 1.5rem 1.9rem;
  font-size: 0.83rem;

  .text-1 {
    text-align: right;
    margin-bottom: 1rem;
  }
}
</style>
